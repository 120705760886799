import React, {useState, useContext, useEffect, memo, useCallback } from "react"
import ThemeContext from "../utils/theme"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import { Container, Image, InputGroup, FormControl, Form, Button, Modal, CloseButton, ProgressBar, OverlayTrigger, Popover, Table} from "react-bootstrap"
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import firebase from "../components/firebase"

import { MapContainer, TileLayer, Marker, Popup, Circle,useMap } from 'react-leaflet'

import { UserData }  from  "../components/UserData"
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './grid.css';


import { loggedUser, printing } from  "../components/FirebaseAuth"
import { LoadStateHouse, LoadRandomHouse } from  "../components/LoadStateHouse"
import { Link } from "@reach/router"


const GetHouseData = async(localUserData) =>
{
  //console.log("------- CALLED GET HOUSE DATA")
  let currentUser = loggedUser()

  let searchParams
  if (typeof window !== 'undefined') 
  { searchParams = new URLSearchParams(window.location.search); }

  let stateChosen
  let mlsChosen
  if (searchParams)
  {
    stateChosen = searchParams.get('state')
    mlsChosen = searchParams.get('mls')
    //console.log(stateChosen,mlsChosen)
  }
  let played = false
  let altReturn ={}

  /*if (currentUser && currentUser.uid)
  {
    console.log("RUNNING Users FIREBASE CALL")
    await firebase.firestore().collection('Users').doc(currentUser.uid).get()
    .then((doc) => {
      if (doc.exists) 
      {
          //console.log("Document data:", doc.data());
          //doc.data()
          let userData = doc.data()
          let keyName = `${mlsChosen}_${stateChosen}`

          if (userData[keyName])
          {
            //console.log("has already played this map")
            //played = true
            let ret = {"played":true, "points":userData[keyName][0]}
            //console.log("return",ret)
            altReturn = ret
          }

      } 
      else 
      {
          // doc.data() will be undefined in this case
          console.log("No such user!");
      }
    }).catch((error) => {
        console.log("Error getting user:", error);
    });
  }
  */


  if (localUserData != null)
  {
    //console.log("User data:", localUserData);
    let userData = localUserData
    let keyName = `${mlsChosen}_${stateChosen}`

    if (userData[keyName])
    {
      //console.log("has already played this map")
      //played = true
      let ret = {"played":true, "points":userData[keyName][0]}
      //console.log("return",ret)
      altReturn = ret
    }
  }
  else
  {
    console.log("user DATA is null")
  }

  
  //console.log("RUNNING States FIREBASE CALL")
  return firebase.firestore().collection('States').doc(String(stateChosen)).collection('buffer').doc(mlsChosen).get()
  .then((doc) => {
    if (doc.exists) 
    {
        //console.log("Document data:", doc.data());
        altReturn["house"] = doc.data()
        return altReturn
    } 
    else 
    {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });
}

let first = 0

export default ({location}) => {

  let currentUser = loggedUser()

  //let userData = UserData()
  const [userData, setUserData] = useState({})

  const[mlsID,setmlsID] = useState("")

  const [alreadyPlayed,setAlreadyPlayed] = useState();
  const [firstPageLoad, setFirst] = useState(0)
  const [previousScore,setPreviousScore] = useState(0);
  const [houseData,setHouse] = useState({});
  const [pics, setPictures] = useState([]);
  const [feats, setFeats] = useState([{category:"filler",text:["filler", "filler"]}]);
  
  const [taxAss, setTaxAss] = useState([{assessment:{total:"filler"},year:"filler"}]);
  const [readTax, setReadTax] = useState(false)
  const [taxModalShow, setTaxModalShow] = useState(false);

  const [trendModalShow, setTrendModalShow] = useState(false);
  const [trend, setTrend] = useState({median:"filler"});
  const [readTrend, setReadTrend] = useState(false)

  const [listItems, setListItems] = useState(null)
  const [listName, setListName] = useState("")

  const [ scoreSlider, setScoreSlider ] = useState(0); 

  
  const [priceSub, setPriceSub] = useState(0);
  const [priceComma, setPriceComma] = useState();
  const [hideInput, setHideInput] = useState(false);

  const [ogGuess, setOgGuess] = useState(0);
  const [priceDiff, setPriceDiff] = useState(0);
  const [percentageScore, setPercentageScore] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const { dark } = useContext(ThemeContext)
  

  const [rerendering, setRerender] = useState(0.0)

  const [latLongPoint, setLatLongPoint] = useState([0.0, 0.0])
  const [mapModalShow, setMapModalShow] = useState(false);

  let houseSize = 0
  let houseUnits = ""
  let housePrice = 0
  let houseCity = ""
  let houseState = ""
  let houseLine = ""
  let houseZip = ""
  let houseLat = 0
  let houseLon = 0
  let houseYear = 0

  const loadPageData = (pageData) =>
  {
    setAlreadyPlayed(false)
    setHouse(pageData)
    let tempPic = []
    pageData.photos.forEach(element => {
      let str = element.href
      let trim = str.substring(0, (str.length-5))
      let better = trim.concat("od-w1024_h768_x2.webp")
      tempPic.push({src:better})
    });

    setFeats(pageData.features)
    setTaxAss(pageData.tax_history)
    setPictures(tempPic)

    
    if(pageData.trend && pageData.trend.median)
    {
      let propData = pageData.trend.median.by_prop_type
      //console.log("trend Data",propData)
      setTrend(propData)
    }
    else
    {
      let propData = {median:'none'}
      setTrend(propData)
    }

    //console.log("pic Data is ",tempPic)
    //houseData = getHouse
    //console.log("house Data is ",pageData)
  }

  //let houseData = {}
  //console.log("information is ",location)
  useEffect(() => 
  {
    
    (async () =>
    { 
      houseLat = 0
      houseLon = 0
      let userStorage = JSON.parse(localStorage.getItem('userData'))
      if (userStorage == null)
      {
        if(currentUser && currentUser.uid)
        {
          //console.log("RUNNING Users FIREBASE CALL")
          await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
          .then((doc) => {
            if (doc.exists) 
            {
                //console.log("Document data:", doc.data());
                //doc.data()
                setUserData(doc.data())
                localStorage.setItem('userData', JSON.stringify(doc.data()) )
            } 
            else 
            {
                // doc.data() will be undefined in this case
                console.log("No such user!");
            }
          }).catch((error) => {
              console.log("Error getting user:", error);
          });
        }
      }
      else 
      {
        setUserData(userStorage)
        //console.log("didnt run firebase call because data is in local storage", userStorage)
      }


      

      let searchParams
      if (typeof window !== 'undefined') 
      { searchParams = new URLSearchParams(window.location.search); }

      let listID
      if(searchParams)
      { listID = searchParams.get('listID') }
      
      //console.log("list id is",listID)
      if(listID)
      {
        //console.log("RUNNING CATEGORIES FIREBASE CALL")
        await firebase.firestore().collection('Categories').doc(String(listID)).get()
        .then((doc) => {
          if (doc.exists) 
          {
            let tempList = doc.data().List
            //tempList["listID"]=listID
            //console.log("Document DDDDDDDdata:", tempList);
            setListItems(tempList)
            setListName(doc.data().Name)
          } 
          else 
          {
            // doc.data() will be undefined in this case
            console.log("No such item!");
          }
        }).catch((error) => {
            console.log("Error getting items:", error);
        });
      }
      else 
      {
        setListItems(null)
      }


      if(location && location.state && location.state.pageInfo) //data sent from previous page
      {
        //console.log("------- USED INFO FROM PREVIOUS PAGE")
        loadPageData(location.state.pageInfo)

        let getHouse
        if (userStorage != null)
        {  
          getHouse = await GetHouseData(userStorage) 
          //console.log("ran with userStorage")
        }
        else
        { 
          getHouse = await GetHouseData(userData)  
          //console.log("ran with userdata")
        }

        if(getHouse && getHouse.played != undefined)
        {
          setPreviousScore(getHouse["points"])
          setAlreadyPlayed(true)
        }
        //location = undefined
      }
      else //page was navigated via diret link
      {
        //console.log("there was no houseinfo")
        let getHouse
        if (userStorage != null)
        {  
          getHouse = await GetHouseData(userStorage) 
          //console.log("ran with userStorage",userStorage)
        }
        else
        { 
          getHouse = await GetHouseData(userData)  
          //console.log("ran with userdata",userData)
        }
        if(getHouse["played"] == undefined)
        {
          loadPageData(getHouse["house"])
        }
        else
        {
          loadPageData(getHouse["house"])
          setPreviousScore(getHouse["points"])
          setAlreadyPlayed(true)
        }
      }

    })()
  }, [mlsID, rerendering])
  let photoWidth = '600';
  let photoHeight = '400';

  let listDate
  if(houseData.list_date)
  {
    let listDay = (houseData.list_date)
    let listDiv = listDay.search("T")
    listDate = listDay.substring(0,listDiv)
  }

  let minLat,maxLat,minLon,maxLon = 0

  if(houseData)
  {
    housePrice = houseData.price
    
    if(houseData.building_size)
    {
      houseSize = houseData.building_size.size
      houseUnits = houseData.building_size.units
    }
    if(houseData.address)
    {
      houseCity = houseData.address.city
      houseState = houseData.address.state
      houseLine = houseData.address.line
      houseZip = houseData.address.postal_code
      houseLat = houseData.address.lat
      houseLon = houseData.address.lon
      houseYear = houseData.year_built
      //setLatLongPoint([parseFloat(houseLat), parseFloat(houseLon)])

      //console.log("lat and long are", houseLat, houseLon)
      //console.log("year built", houseData.year_built, houseData)
      
      let Rad = 7; // miles
      // Calculate bounding box coordinates with geodetic approximation (WGS84)
      let a = 6378137; // Radius of earth at equator (m)
      let e2 = 0.00669437999014; // eccentricity squared
      let m = 1609.344; // 1 mile in meters
      let r = Math.PI / 180; // convert to radians
      //Distance of 1° latitude (miles)
      let d1 = r * a * (1 - e2) / (1 - e2 * Math.sin(houseLat * r) ^ 2) ^ (3 / 2) / m;
      //Distance of 1° longitude (miles)
      let d2 = r * a * Math.cos(houseLat * r) / Math.sqrt(1 - e2 * Math.sin(houseLat * r) ^ 2) / m;
      minLat = houseLat - Rad / d1;
      maxLat = houseLat + Rad / d1;
      minLon = houseLon - Rad / d2;
      maxLon = houseLon + Rad / d2;
    }
  }


  const SubmitPrice = async (evt) => 
  {
    if(evt)
    { evt.preventDefault(); }
    //console.log("Submitted Price",priceSub)
    //console.log("Actual Price",housePrice)
    //setPriceDiff(Math.abs(priceSub-housePrice))
    //console.log("Difference",Math.abs(priceSub-housePrice))

    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    let mlsChosen
    if(searchParams)
    {
      stateChosen = searchParams.get('state')
      mlsChosen = searchParams.get('mls')
    }
    //let pointsEarned = Math.floor( 100*Math.pow(0.999969,(Math.abs(priceSub-housePrice)) ) )
    let percent = (Math.abs(priceSub-housePrice) /housePrice)
    let pointsEarned = Math.round( 500*(1-2*(Math.pow(percent,2)) ))

    if (pointsEarned < 0)
    {
      setOgGuess(0)
    }
    else
    {
      setOgGuess(pointsEarned)
    }

    if(readTax == true)
    {
      pointsEarned = (pointsEarned - (500*(0.1)))
    }

    if(readTrend == true)
    {
      pointsEarned = (pointsEarned - (500*(0.2)))
    }

    setPercentageScore(percent)
    if (pointsEarned < 0)
    {
      pointsEarned = 0
    }

    let showScore  = pointsEarned + 1

    
    setPriceDiff(showScore)  

    
    // let entry =
    // {
    //  "state":`${stateChosen}`, 
    //  "mls": `${mlsChosen}`,
    //  "points": `${pointsEarned}`       
    // }
    let keyName = `${mlsChosen}_${stateChosen}`

    let entry = { [keyName]:`${pointsEarned}` }
    if(currentUser && currentUser.uid)
    {
      let userID = `${currentUser.uid}`
      let previousData = {}

      let userStorage = JSON.parse(localStorage.getItem('userData'))

      if (userStorage == null)
      {
        //console.log("RUNNING Users FIREBASE CALL")
        await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
        .then((doc) => {
          if (doc.exists) 
          {
              //console.log("Document data:", doc.data());
              //doc.data()
              previousData = doc.data()
              localStorage.setItem('userData', JSON.stringify(doc.data()) )
          } 
          else 
          {
              // doc.data() will be undefined in this case
              console.log("No such user!");
          }
        }).catch((error) => {
            console.log("Error getting user:", error);
        });
      }
      else 
      {
        previousData = userStorage
        //console.log("didnt run Submit Price Users firebase read because data is in local storage", userStorage)
      }
      

      let addr = `${houseLine}, ${houseCity}, ${houseState}, ${houseZip}`

      let thumbNail = houseData.photos[0].href
      let trim = thumbNail.substring(0, (thumbNail.length-5))
      let betterThumbNail = trim.concat("od-w1024_h768_x2.webp")

      previousData[keyName] = [pointsEarned,betterThumbNail]

      let accuracy = ((1-percent)*100).toFixed(2)
      if (accuracy < 0)
      { accuracy = 0} 
      //console.log("accuracy is ", accuracy)

      if(houseData.price > 1000000 && accuracy > 90.0)
      {
        let badgeKey = "badge:Millionaire.Club"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        { 
          completed = 1
        }
        let goal = 10
        let des = "Score over 90% on houses greater than $1,000,000"
        let icon = "MillionairClub.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }

      if(houseSize > 3000 && accuracy > 80.0)
      {
        let badgeKey = "badge:Mansion.Master"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        { 
          completed = 1
        }
        let goal = 20
        let des = "Score over 80% on houses larger than 3000 sqft"
        let icon = "Mansion.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }
      if(houseData.baths > houseData.beds)
      {
        let badgeKey = "badge:Oval.Office"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        {  
          completed = 1
        }
        let goal = 50
        let des = "Complete 50 houses that have more bathrooms than bedrooms"
        let icon = "OvalOffice.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }

      //console.log("entry would be", previousData,"user is", userID)

      //console.log("SETTING USERS FIREBASE CALL")
      firebase.firestore().collection('Users').doc(String(userID)).set(previousData)
      
      localStorage.setItem('userData', JSON.stringify(previousData) )
      //console.log("updated userData in localstorage", localStorage)
    }

    setPriceComma("")
    
    let num = Math.random() + Math.random() + Math.random()
    setRerender(num)
    //console.log("rerender was just called")

    setTimeout(() => 
    {  
      setModalShow(true)
      if(!currentUser)
      {
        setHideInput(true)
        setTrend({median:'filler'})
      }
    }, 600);

  }

  const LoadWithState = async (evt) => 
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    if(searchParams)
    {
      stateChosen = String(searchParams.get('state'))
    }

    LoadStateHouse(stateChosen,userData)

    setTimeout(() => 
    {  
      let num = Math.random() + Math.random() + Math.random()
      setHideInput(false)
      setRerender(num)
      setReadTax(false)
      setReadTrend(false)
      setTrend({})
      //console.log("Called rerender")
    }, 600);
  
  }
  const LoadWithRandom = async (evt) => 
  {
    if(evt)
    { evt.preventDefault(); }
    LoadRandomHouse(userData)

    setTimeout(() => 
    {  
      let num = Math.random() + Math.random() + Math.random()
      setHideInput(false)
      setRerender(num)
      setReadTax(false)
      setReadTrend(false)
      setTrend({})
      //console.log("Called rerender")
    }, 600);
  }

  function Comma(Num) 
  { //function to add commas to textboxes
    if (Num == null)
    {
      Num = ''
    }
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    let x = Num.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }

  function MyVerticallyCenteredModal(props) 
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    let mlsChosen
    if(searchParams)
    {
      stateChosen = searchParams.get('state')
      mlsChosen = searchParams.get('mls')
    }

    let accuracy = ((1-percentageScore)*100).toFixed(2)
    if (accuracy < 0)
    { accuracy = 0} 
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            You guessed <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(priceSub).replace(/\D/g,''))}</span>! 
            The actual list price is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span>!
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>

          <h4>You scored {priceDiff - 1} (Max score 500).</h4>
          <p>

            
            {( () =>
              {
                
                let acc = ( () => { return (<> You were <span style={{color:"#00DD00",fontWeight:"700"}}>{accuracy}%</span> accurate.<br/></>) } )()
                let sec, taxRead, trendRead, original 

                let pBar = ( () => { return ( <ProgressBar style={{backgroundColor:'#00dd00 !important', width:'80%'}} now={accuracy} label={`${accuracy}%`} /> ) } )()
                /*let pBar = ( () => 
                { return ( 
                  <RangeSlider
                    value={scoreSlider}
                    onChange={changeEvent => setScoreSlider(changeEvent.target.value)}
                  />
                ) } )()
                */
                if ((ogGuess) <= 0)
                { 
                  //console.log("IN HERE")
                  sec = ( () => { return (<><br/> You need to be within <span style={{color:"#00DD00",fontWeight:"700"}}>30%</span> accuracy to score points. <br/></>) } )()
                  
                }
                
                if (readTax)
                {
                  original = ( () => { return (<><br/> Original score was {ogGuess}: </>) } )()
                  taxRead = ( () => { return (<><br/>-50 from the total score for using the Tax Assessed Value.</>) } )()
                }
                if (readTrend)
                {
                  original = ( () => { return (<><br/> Original score was {ogGuess}: </>) } )()
                  trendRead = ( () => { return (<><br/>-100 from the total score for using Average Home Price.</>) } )()
                }
                let askLogIn
                if (!currentUser)
                {
                  askLogIn = ( () =>
                  {
                    return(
                      <>
                        <h4>Make an <Link to="/Signup" style={{ fontWeight:"700"}}>account</Link> to track your score!</h4>
                      </>
                    )
                  })()
                }
                return (<> {askLogIn} <br/> {pBar} {acc} {sec} {original} {taxRead} {trendRead} </>)

              }
            )()}
             <br/><br/>
            <Link to="/About" style={{ fontSize:"1rem",fontWeight:"800"}}>(Learn more about how the score is calculated)</Link>
          </p>
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          {/*
          <Button onClick={LoadWithRandom}>New Random Home?</Button>
          <Button onClick={LoadWithState}>New {houseState} Home?</Button> 
          */}
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function NewScoreModal(props) 
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    let mlsChosen
    if(searchParams)
    {
      stateChosen = searchParams.get('state')
      mlsChosen = searchParams.get('mls')
    }

    let accuracy = ((1-percentageScore)*100).toFixed(2)
    if (accuracy < 0)
    { accuracy = 0} 
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            You scored <span style={{color:"#00DD00",fontWeight:"800"}}>{priceDiff - 1}</span> points (Max score 500).
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>

          <p>

            
            {( () =>
              {
                
                let sec, taxRead, trendRead, original 

                let labelText = ( () => { return (<>Actual list price is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span></>) } )()

                let pBar = ( () => { return (
                <>
                  <Table striped bordered hover variant={`${dark ? 'dark' : ''}`} style={{ width:'100%'}}>
                    <thead>
                      <tr>
                        <th>Your Guess</th>
                        <th>List Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(priceSub).replace(/\D/g,''))}</span>
                        </td>
                        <td className="blurPrice">
                          <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  
                  You were <span style={{color:"#00DD00",fontWeight:"800"}}>{accuracy}%</span> accurate.
                  <ProgressBar style={{backgroundColor:'#00dd00 !important', width:'100%',fontSize:'15px',height:'20px'}} now={accuracy} /> 
                  
                </> 
                ) } )()
                /*let pBar = ( () => 
                { return ( 
                  <RangeSlider
                    value={scoreSlider}
                    onChange={changeEvent => setScoreSlider(changeEvent.target.value)}
                  />
                ) } )()
                */
                if ((ogGuess) <= 0)
                { 
                  //console.log("IN HERE")
                  sec = ( () => { return (<><br/> You need to be within <span style={{color:"#00DD00",fontWeight:"700"}}>30%</span> accuracy to score points. <br/></>) } )()
                  
                }
                else
                {
                  if (readTax)
                  {
                    original = ( () => { return (<><br/>Base score: <span style={{fontWeight:"900"}}>{ogGuess}</span></>) } )()
                    taxRead = ( () => { return (<><br/><span style={{color:"#DD2222",fontWeight:"900"}}>-50</span> for using Tax Assessed Value.</>) } )()
                  }
                  if (readTrend)
                  {
                    original = ( () => { return (<><br/>Base score: <span style={{fontWeight:"900"}}>{ogGuess}</span></>) } )()
                    trendRead = ( () => { return (<><br/><span style={{color:"#DD2222",fontWeight:"900"}}>-100</span> for using Average Home Price.</>) } )()
                  }
                }
                let askLogIn
                if (!currentUser)
                {
                  askLogIn = ( () =>
                  {
                    return(
                      <>
                        &#9702;&nbsp;<Link to="/Signup" style={{ color:"#00DD00",fontSize:"1.1rem",fontWeight:"800"}}>Make an account</Link> to track your score!
                      </>
                    )
                  })()
                }
                let learnMore = ( () => { return (
                <><br/><Link to="/About" style={{ color:"#00DD00",fontSize:"1.1rem",fontWeight:"800"}}>Learn more</Link> about how the score is calculated </>) } )()

                return (
                <> 
                  {pBar} {sec} {original}{taxRead} {trendRead} <br/> 
                  <div style={{textAlign:'left'}}>
                    {learnMore} {askLogIn}  
                  </div>    
                </>)

              }
            )()}
          </p>
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <div className="scoreBottomButton-grid">  
            <div className="scoreBottomButtonBoxes">
              <Button style={{fontWeight:"800",height: 'inherit', textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
              onClick = { () => 
              {
                LoadWithRandom() 
                props.onHide()
              } }>
                New Random Home
              </Button>
            </div>
            <div className="scoreBottomButtonBoxes">
              <Button style={{fontWeight:"800",height: 'inherit', textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}
              onClick = { () => 
              {
                LoadWithState() 
                props.onHide()
              } }>
                New {houseState} Home
              </Button>
            </div>
            <div className="scoreBottomButtonBoxes">
              <Button style={{height: 'inherit'}} variant="danger" onClick={props.onHide}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function TaxModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Tax Assessed Value
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <p>
            The tax assessed value takes into account the property condition, square footage, and sales of similar homes.
            The actual sale price can be much greater or lower than the tax assesed value.
          </p>
          <p className="thicc">
            Unlocking the tax assessed value will lower your score by 50.
          </p>
          <br/>
          <Button onClick={()=>{
            setReadTax(true) 
            setTaxModalShow(false)
          }} style={{ fontSize:"1.5rem",fontWeight:"500",color:"white"}} variant="primary">
            Show Taxes?
          </Button>
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function TrendModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Average House Price
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          { (() => {
            //console.log("trend",trend)


            function toTitleCase(str) 
            {
              return str.replace(
                /\w\S*/g,
                function(txt) 
                {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
              );
            }

            function modString(str)
            {
              if( str == 'home')
              {
                str = 'Average Property'
              }
              str = str.replace(/_/g, ' ');
              return toTitleCase(str)
            }

            if( readTrend )
            {
              return(
                <>
                  <Table striped bordered hover variant={`${dark ? 'dark' : ''}`} style={{ width:'100%', margin:'auto'}}>
                    <tbody>
                      
                        {
                          Object.entries(trend).map((item,ind) =>
                          {
                            //console.log("prop",item[1])
                            if(item[1] && item[1].listing_price)
                            {
                              //console.log("int here")
                              return (
                              <>  
                              <tr>
                                <td style={{color:"#00DD00",fontWeight:"800"}}>
                                  ${Comma(String(item[1].listing_price).replace(/\D/g,''))}
                                </td>
                                <td key={`trend-${ind}`} >
                                  {modString(item[0])}
                                </td>
                              </tr>
                              </>
                              )
                            }
                          })
                        }
                    </tbody>
                  </Table>
                  
                </>
              )
            }
            else
            {
              return(
                <>
                  <p className="thicc">
                    This will show the average house price in the area by house type.  <br/>             
                  </p>
                  <p className="thicc">
                    Unlocking this will lower your Final Score by 100.
                  </p>
                  <br/>
                  <Button onClick={ ()=>
                  {
                    setReadTrend(true) 
                    //setTrendModalShow(false)
                  }} 
                  style={{ fontSize:"1.5rem",fontWeight:"500",color:"white"}} variant="primary">

                    Show Average Price

                  </Button>
                </>
              )
            }
          })()
          }
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const BigMap = useCallback(({ center, zoom }) => {
    const [map, setmap] = useState(null);
    if (map) {
      map.flyTo(center);
    }
    //console.log("center is",center,"zoom is",zoom)
    return (
      <>
        <MapContainer style={{width:'100%',height:'350px'}}
          center={center}
          zoom={zoom}
          attributionControl={false}
          zoomControl={false}
          whenCreated={setmap}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></TileLayer>
          <Circle center={center} pathOptions={{color: '#00DD00'}} radius={400}></Circle>
          {/*
            <Marker position={center}>
            </Marker>
          */}
        </MapContainer>
      </>
    );
  }, [houseData])

  const SmallMap = useCallback(({ center, zoom }) => {
    const [map, setmap] = useState(null);

    useEffect(() => {
      if (map) {
        map.flyTo(center);
      }
    }, [])
    //console.log("center is",center,"zoom is",zoom)
    return (
      <>
        <MapContainer style={{width:'100%',height:'150px'}}
          center={center}
          zoom={zoom}
          attributionControl={false}
          zoomControl={false}
          whenCreated={setmap}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></TileLayer>
          <Circle center={center} pathOptions={{color: '#00DD00'}} radius={400}></Circle>
          {/*
            <Marker position={center}>
            </Marker>
          */}
        </MapContainer>
      </>
    );
  }, [houseData])

  function MapModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Full Map
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          
          <BigMap center={[parseFloat(houseData.address.lat),parseFloat(houseData.address.lon)]} zoom={13}/>
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  return (
    <PageLayout>
       <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
   integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
   crossorigin=""/>
      <SEO title="Play Page" />

      {(() => 
        {
          if(listItems)
          {
            return(
            <>
              
              
              <div className="pageMargin pt-6 mt-6 text-color">  
                <div className="text-left">Continue {listName}: </div>   
                <div className="text-left thicc play-grid scrolling">
                {
                  
                  Object.entries(listItems).map((f,index) =>
                  {
                    //console.log("EEEEEE")
                    let played = false
                    let points = 0
                    let address = f[1]

                    let mlsParam, stateParam
                    let params = String(f[0])
                    if (params.length > 0)
                    {
                      let divider = params.search("_")
                      mlsParam = params.substring(0,divider)
                      stateParam = params.substring((divider+1))
                    }
                    Object.entries(userData).map((t,k) =>
                    {
                      if(t[0] == f[0])
                      {
                        played = true
                        points = t[1][0]
                      }
                    })
                        
                    //console.log("list entry",f)
                    let searchParams
                    if (typeof window !== 'undefined') 
                    { searchParams = new URLSearchParams(window.location.search); }

                    let listIDHash
                    if(searchParams)
                    { listIDHash = searchParams.get('listID') }

                    let imgWidth = "150px" 
                    let imgHeight ="100px" 
                    if(played)
                    {
                      return(
                        <>
                        <div key={`listedItems-${index}`} className="playBox">
                          <div className="imgContainer">
                            <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${listIDHash}` } style={{color:"#00DD00"}} onClick={()=>{
                              setmlsID(mlsParam)
                              setReadTax(false)
                              setReadTrend(false)
                              setTrend({median:"filler"})
                              setHideInput(false)
                              }}>
                              <img src={address} className="imgGray" alt="house" style={{maxWidth:`${imgWidth}`, maxHeight:`${imgHeight}` , border:"5px solid #00DD00",borderRadius: "10px",margin:"5px"}}></img> 
                              <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                                Scored {points}
                              </div>
                            </a>
                          </div>
                        </div>
                        </>
                      )
                    }
                    else 
                    {
                      
                      if (mlsParam!="" && stateParam!="")
                      {
                        return(
                          <>
                          <div className="playBox">
                            <div key={`listedItems-${index}`} className="imgContainer ">
                              <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${listIDHash}`} onClick={()=>{
                                setmlsID(mlsParam)
                                setReadTax(false)
                                setReadTrend(false)
                                setTrend({median:"filler"})
                                setHideInput(false)
                                }}>
                                <img src={address} alt="house" style={{maxWidth:`${imgWidth}`, maxHeight:`${imgHeight}` , border:"5px solid #007bff",borderRadius: "10px",margin:"5px"}} ></img> 
                              </a>
                            </div>
                          </div>
                          </>
                        )
                      }
                    }
                  })
                }
                </div>
              </div>

              <br/>

            </>
            )
          }
        }
        )()
      }

      {(()=>
        {
          if (listItems == null)
          {
            return( 
            <>
              <table style={{margin:'auto'}}>
                <tr>
                  <td>
                    <Button style={{fontWeight:"900", width:"100%", textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
                    onClick={LoadWithRandom}>
                      New Random Home
                    </Button> 
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <Button style={{fontWeight:"900",width:"100%",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}
                    onClick={LoadWithState}>
                      New {houseState} Home
                    </Button> 
                  </td>
                </tr>
              </table>
              <br/><br/>
            </>)
          }
        }
        )()
      }
      
      <div>
          
        {(()=>
          {
            if (alreadyPlayed == false && trend.median != "filler") // if user has not played house
            {
              if (trend.median == "none") // if no trend data
              {
                return( 
                <h3> 
                  Guess the price! 
                </h3>  )
              }
              else
              {
                return( 
                  <h3> 
                    Guess the price! &nbsp; 
                    <Button onClick={()=>{setTrendModalShow(true)}} style={{fontWeight:"700",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} variant="primary"> 
                      Hint? 
                    </Button> 
                  </h3>  )
              }
            }
            else if (alreadyPlayed == false && trend.median == "filler") //if user is not logged in and just submitted a price
            {
              return( 
              <>
                <h3> You Scored {priceDiff - 1}!!!</h3> 
              </>)
            }
            else //if user is logged in and has a previously submitted price (aka alreadyPlayed = true)
            {
              return( 
              <>
                <h3> You Scored {previousScore}!!!</h3> 
              </>)
            }
          }
          )()
        }
      </div>

      <br/>

        {(()=>
          {
            const parceInp = useCallback((e) =>
            {
              let val = e.target.value
              val = String(val).replace(/\D/g,'');
              let ret = Comma(val)
              setPriceComma(ret)
              let valClean = String(val).replace(',','')
              setPriceSub(valClean) 
            },[setPriceComma])

            if (alreadyPlayed == false && hideInput == false)
            {
              return(
              <div className="inputPrice">
                <InputGroup >
                  <InputGroup.Text style={{backgroundColor:"#00BB00",borderColor:"#00BB00",color:"white", fontSize:"1.5rem",fontWeight:"900"}}>$</InputGroup.Text>
                  <FormControl size="lg" variant="success" type="text" value={priceComma} onChange={(e) => {parceInp(e)}} 
                    onKeyPress = { (e) =>
                    {
                      //console.log(e.key)
                      if(e.key === 'Enter')
                      {
                        SubmitPrice()
                      }
                    }} placeholder="Input a price" />
                  <Button onClick={SubmitPrice} style={{ fontSize:"1.5rem",fontWeight:"500", backgroundColor:"#00BB00",borderColor:"#00BB00"}} variant="success" value="Submit">Submit</Button>
                </InputGroup>
              </div>
              )
            }
            else if (alreadyPlayed == false && hideInput == true)
            {
              return( 
              <>
                <h3> The house price is <span className="blurPrice"> ${Comma(String(housePrice).replace(/\D/g,''))}</span> </h3> 
                <h4><Link to="/Signup" style={{ color:"#00DD00",fontWeight:"800"}}>Make an account</Link> to track your score!</h4>
              </>)
            }
            else 
            {
              return( <h3> The house price is ${Comma(String(housePrice).replace(/\D/g,''))}</h3> )
            }
          }
          )()
        }

      <br/>
      
      <div className="pageMargin pt-6 mt-6 text-color">
        <div className="basic-grid">
          
          <div className="boxes pics-grid ">
            
            {priceDiff ?  <NewScoreModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          /> : ` `}

            
            {taxModalShow ?  <TaxModal
                            show={taxModalShow}
                            onHide={() => setTaxModalShow(false)}
                          /> : ` `}
            
            {trendModalShow ?  <TrendModal
                            show={trendModalShow}
                            onHide={() => setTrendModalShow(false)}
                          /> : ` `}

            {mapModalShow ?  <MapModal
                            show={mapModalShow}
                            onHide={() => setMapModalShow(false)}
                          /> : ` `}

            <div className="picSize">
              <Carousel images={pics} transitionDurationMax={40} style={{ height: 400, backgroundColor:'transparent' }} hasMediaButton={false} hasIndexBoard={false} />
            </div>
            
          </div>
          <div className="boxes text-left thicc " >
            <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
              
            <div className="addrMap-grid">
              <div className="addrMapBoxes">
                {(() => 
                  {
                    if (alreadyPlayed == false && hideInput == false)
                    {
                      return(
                        <>
                          {houseCity}, {houseState}, {houseZip} <br/>
                        </>
                      )
                    }
                    else
                    {
                      return( 
                        <>
                          {houseLine}, {houseCity}, {houseState}, {houseZip} <br/>
                        </>
                        )
                    }
                  }
                  )()
                }

                {houseData.beds} <div className="thin">Beds</div> {houseData.baths} <div className="thin">Baths</div>&nbsp; 
                {houseSize} <div className="thin">{houseUnits}</div> {houseData.style} <br/>
                
                {(() => 
                  {
                    if(houseYear)
                    {
                      return(
                        <> {houseYear} <div className="thin">Year Built</div> <br/> </>
                      )
                    }
                  })()
                } 

                {(() => 
                  {
                    if(listDate)
                    {
                      return(
                        <> { listDate } <div className="thin">List Date</div> <br/> </>
                      )
                    }
                  })()
                } 

                
              </div>
              <div className="addrMapBoxes">
                {(() => 
                  {
                    if(houseData && houseData.address)
                    {
                      return(
                        <div style={{position:'relative'}}>
                          <SmallMap center={[parseFloat(houseData.address.lat),parseFloat(houseData.address.lon)]} zoom={12}/>
                          <button onClick={()=>{setMapModalShow(true)}} style={{opacity:'0.75',backgroundColor:'transparent',border:'none', position:'absolute',bottom:'5px',zIndex:'900'}}> 
                            
                            <svg style={{stroke:'#555',fill:'#fff'}} height="30" preserveAspectRatio="xMidYMid meet" role="img" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
                              <title>Full Map</title>
                              <path d="m1 1v10h5v-5h5v-5zm18 0v5h5v5h5v-10zm-18 18v10h10v-5h-5v-5zm18 5v5h10v-10h-5v5z"></path>
                            </svg>
                          </button>
                        </div>
                      )
                    }
                  })()
                }  
              </div>
            </div> 
            <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
          </div>

          
          <div className="boxes text-left thicc scrolling">
            <u>Description:</u><br/>
            <div className="thin">{houseData.description}</div>
            {
            // <iframe width="300" height="250" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" 
            // src={`https://www.openstreetmap.org/export/embed.html?mlat=${houseLat}&mlon=${houseLon}&bbox=${minLon}%2C${minLat}%2C${maxLon}%2C${maxLat}&amp;layer=mapnik#map=11/${houseLat}/${houseLon}`} 
            // style={{border: `1px solid black`}}>
            // </iframe><br/>
            }
          </div>
          <div className="boxes text-left features-grid thicc ">
            <Accordion>
              <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}><u>Details:</u></Accordion.Button>
                <Accordion.Body >
                  <div className="thin feat-grid" style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}` }}>
                    {(()=>
                      { 
                        //console.log("tax assesed ",taxAss.length)
                        if (taxAss.length > 0)
                        {
                          if(!readTax)
                          {
                            return(
                              <>
                                <div className="featBoxes">
                                  <div className="mid">Tax Assessed Value </div>
                                  <Button onClick={()=>{setTaxModalShow(true)}} style={{fontWeight:"700", width:"80%", margin:"0 auto", fontSize:"1.2rem", textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
                                  variant="primary">Unlock Tax Hint?</Button>
                                </div>
                              </>
                            )
                          }
                          else
                          {
                            return(
                            <>
                              <div className="featBoxes">
                                <div className="mid">Tax Assessed Value </div>
                                <ul>
                                  { taxAss && taxAss.map((e,ind) => 
                                    {
                                      if(e.assessment)
                                      {
                                        return (
                                          <div key={`tax-${ind}`} >
                                            <li>{e.year} - ${e.assessment.total}</li>
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                </ul>
                              </div>
                            </>
                            )
                          }
                        }
                        else
                        {
                          return(
                            <>
                              <div className="featBoxes">
                                <div className="mid">Tax Assessed Value </div>
                                <div style={{fontWeight:"600",color:"red"}}>There is no Tax data available for this house. </div>
                              </div>
                            </>
                          )
                        }
                      }
                      )()
                    }
                    { 
                      feats.map((e,ind) => 
                      {
                        return (
                          <div key={`featCat-${ind}`} className="featBoxes" >
                            <div className="mid">{e.category} </div>
                            <ul>
                              {
                                e.text.map((f,index) =>
                                {
                                  return (
                                    <li key={`featList-${index}`}>
                                      {f}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        )
                      })
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>    
          </div>  
        </div>

        

        {
        // <div style={{ margin: 'auto', width:'100%'}}>
        //   <Carousel 
        //     plugins={[
        //       'arrows',
        //       'infinite'
        //     ]}
        //   >
        //     { 
        //       pics.map((e,ind) => 
        //       {
        //         return ( 
        //           <img height={photoHeight}  key={ind} fluid src={e} alt={"Appraisir"} />
        //         )
        //       })
        //     } 
        //   </Carousel>
        // </div>
        // <br/>
        // <div className="text-left">
        //   <h3 className="last-name" style={{display: `inline-block`}}> {houseData.style} </h3>&nbsp;
        // </div>
        // <div className="text-left">         
        //   <h3 style={{display: `inline-block`}}> <div style={{display: `inline-block`}} className="last-name">{houseData.beds}</div>  Beds</h3> &nbsp;
        //   <h3 style={{display: `inline-block`}}> <div style={{display: `inline-block`}} className="last-name">{houseData.baths}</div> Baths</h3> &nbsp;
        //   <h3 style={{display: `inline-block`}}> 
        //     <div style={{display: `inline-block`}} className="last-name">{houseSize}</div> 
        //     &nbsp;{houseUnits}
        //   </h3> 
        // </div>
        // <div className="text-left">
        //   <h3 style={{display: `inline-block`}}> Year Built: <div style={{display: `inline-block`}} className="last-name">{houseData.year_built} </div> </h3>
        // </div>        
        // <h3 className="text-left"> Description: </h3> 
        // <div className="text-left last-name"> {houseData.description} </div>
        }

        
      </div>
      
    </PageLayout>
  )

}